<template>
  <div class="container-fluid">
    <div class="page-title-box">
      <bread-crumb
        :breadcrumbs="[
          { label: 'Сотрудники', routeName: 'User' },
          { label: 'Редактирование' },
        ]"
      />
      <h4 class="page-title">Редактирование</h4>
    </div>

    <div class="card">
      <div class="card-body">
        <Form
          :service="service"
          :actions="[{ action: 'save', to: (data) => '/user/' + data.id }]"
        >
          <FormField label="Фамилия" :service="service" field="surname" />
          <FormField label="Имя" :service="service" field="name" />
          <FormField label="Отчество" :service="service" field="secondName" />
          <FormField label="Должность" :service="service" field="workPos" />
          <FormField
            label="Дата рождения"
            :service="service"
            field="birthday"
            type="calendar"
          />
          <FormField label="Email" :service="service" field="email" />
          <FormField label="Телефон" :service="service" field="number" />
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/ui/BreadCrumb.vue";
import Form from "@/components/ui/form/Form.vue";
import FormField from "@/components/ui/form/FormField.vue";
import Str from "@/utils/Str";
import UserService from "@/modules/user/services/UserService";

export default {
  components: {
    BreadCrumb,
    Form,
    FormField,
  },
  data() {
    return {
      service: new UserService(),
      Str: Str,
    };
  },
  async created() {
    await this.service.show(this.$route.params.id);
  },
};
</script>
